import React, { Component } from "react";
import Layout from "../components/layout";
import Container from "../components/container";
import styles from "./promocja.module.css";
import { FaInfo, FaPhone, FaUsers } from "react-icons/fa";
import SEO from "../components/seo";
import Hero from "../components/HeroBanner";
import heroImage2 from "../images/promo-empireo-tlo.jpg";
import { graphql } from "gatsby";

export const query = graphql`
  query {
    heroBg: file(
      relativePath: { eq: "promo-empireo-tlo.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Promocja = ({ data }) => {
  return (
      <Layout>
        <SEO
          title="Wypróbuj system przez 30 dni za darmo"
          description="Szukasz 🔎 elastycznego systemu dla swojej agencji? ✅ Zapytaj o bezpłatne demo"
        />
<Hero heroImage={data.heroBg} justifyContent="center">
          <h1>PROMOCJA</h1>
          <br />
          <h2>
            Zamawiając system Empireo do 25 sierpnia 2021 r.
            <br />
            otrzymasz aż 20% rabatu.
          </h2>
        </Hero>
        <Container className={styles.wrapper}>
          <div className={styles.formWrapper}>
            <div className={styles.contactHeader}>
              <FaInfo size="50px" />
              <p>
                <h3>Zasady promocji</h3>
              </p>
              <p>
                <strong>
                  Promocja obowiązuje przy zamówieniach złożonych do 25.08.2021
                  r. i dotyczy:
                </strong>
                <br />
                <br />
                a) zakupu systemu,
                <br />
                b) wynajmu na rok z opłatą jednorazową.
              </p>
              <br />
              <p>
                <FaPhone size="50px" />
              </p>
              <h3>
                Chcesz odebrać rabat?
                <br />
                Napisz lub zadzwoń do nas. +48506504540
              </h3>
            </div>
          </div>
          <div className={styles.mapContainer}>
            <div className={styles.contactHeader}>
              <p>
                <FaUsers size="50px" />
              </p>
              <p>
                <h3>Dlaczego warto wybrać Empireo?</h3>
                <br />
                Nasz system to rozwiązanie skrojone na miarę agencji reklamowych
                i marketingowych. Pozwala sprawnie zarządzać pracą zespołu
                sprzedaży i działu realizacji.
                <br />
                <p>
                  <strong>Empireo = sprzedaż + projekty marketingowe </strong> w
                  jednym miejscu.
                </p>
              </p>

              <p>
                <strong>
                  Jesteśmy przekonani, że z naszym systemem Twoja firma będzie
                  osiągać wyniki, jak nigdy dotąd.{" "}
                </strong>
                Co w sytuacji, gdy będziesz potrzebował dodatkowych możliwości?
                Pamiętaj, że nasz system nieustannie się rozwija. Jeśli mimo to,
                nie będzie oferował interesującej Ciebie funkcji, będziesz mógł
                przejść na nasze dedykowane rozwiązanie{" "}
                <a href="https://aurabusiness.pl/">Aura Business</a>.
                <strong>
                  {" "}
                  Bez najmniejszych problemów z migracją danych oraz przerw w
                  pracy systemu!
                </strong>
              </p>
            </div>
          </div>
        </Container>
    </Layout>
  );
};

export default Promocja;
